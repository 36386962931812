import { configureStore } from "@reduxjs/toolkit";
import scenarioEditorReducer from "../scenarioEditor/slice";

const store = configureStore({
  reducer: {
    scenarioEditor: scenarioEditorReducer,
  },
});

export type TRootState = ReturnType<typeof store.getState>;
export default store;
