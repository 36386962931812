export const setToken = (token: string) => {
  sessionStorage.setItem("auth", token);
};

export const getToken = (): string => {
  return sessionStorage.getItem("auth") || "";
};

export const hasTokenSet = (): boolean => {
  return !!sessionStorage.getItem("auth");
};
