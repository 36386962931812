import { AuthorizedOnly } from "../components/auth/AuthorizedOnly";
import { Layout } from "../components/layout/Layout";
import { Links } from "../components/links/Links";

export const LinksPage = () => {
  return (
    <AuthorizedOnly>
      <Layout>
        <Links />
      </Layout>
    </AuthorizedOnly>
  );
};
