import { FC, ReactNode } from "react";
import "./layout.sass";

type TProps = {
  children: ReactNode;
};

export const Layout: FC<TProps> = ({ children }) => {
  return <div className="layout">{children}</div>;
};
