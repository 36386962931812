import { Card, Typography } from "antd";
import "./Links.sass";
import { useNavigate } from "react-router-dom";
import { ApplicationRoutes } from "../app/routes";

const { Title } = Typography;

export const Links = () => {
  const navigate = useNavigate();

  const handleFormatScenario = () => {
    navigate(ApplicationRoutes.scenarioEditor);
  };

  return (
    <div className="Links">
      <Title level={2}>Applications</Title>
      <div className="cards">
        <Card
          onClick={handleFormatScenario}
          title="Scenario Editor"
          bordered={false}
          style={{ width: "30vw" }}
          className="card"
        >
          <p>Load scenario from text files</p>
          <p>Create preview from text file to visual preview</p>
          <p>Compare text with visual version</p>
        </Card>
      </div>
    </div>
  );
};
