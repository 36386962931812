import { FC, ReactNode } from "react";
import { hasTokenSet } from "../../utils/token";
import { ApplicationRoutes } from "../app/routes";
import { Navigate } from "react-router-dom";

type TProps = {
  children: ReactNode;
};

const NoTokenRedirect = <Navigate to={ApplicationRoutes.main} />;

export const AuthorizedOnly: FC<TProps> = ({ children }) => {
  const token = hasTokenSet();

  if (!token) {
    return NoTokenRedirect;
  }

  return <>{children}</>;
};
