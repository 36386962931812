export const exportTextFile = (fileName: string, fileContent: string) => {
  const dataStr = fileContent;
  const blob = new Blob([dataStr], { type: "application/json" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${fileName}.json`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
