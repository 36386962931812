import { createContext, useMemo, useState } from "react";
import JsonView from "@uiw/react-json-view";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Typography, Upload, notification } from "antd";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import {
  InfoCircleOutlined,
  UploadOutlined,
  DownloadOutlined,
  ReloadOutlined,
  EyeOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import "./ScenarioEditor.sass";
import { TTxtFile, setScenarioFile, setScenarioJSON } from "./slice";
import { TRootState } from "../app/store";
import { useTabInTextArea } from "../../hooks/useTabInTextArea";
import { convertTxtToJSON } from "../../api/scenarioEditor";
import { exportTextFile } from "../../utils/file";
import { ScenarioPreview } from "./ScenarioPreview";
import { TScenario } from "./types";
import { Rules } from "./Rules";
import IdeoLogo from "../../assets/images/ideo_logo.png";

const { Title } = Typography;
const Context = createContext({ name: "Default" });

export const ScenarioEditor = () => {
  useTabInTextArea();
  const dispatch = useDispatch();

  const [api, contextHolder] = notification.useNotification();

  const textFile = useSelector<TRootState, TTxtFile>((state) => state.scenarioEditor.txtFile);
  const jsonFile = useSelector<TRootState, TScenario | undefined>((state) => state.scenarioEditor.jsonFile);

  const [isJSON, setIsJSON] = useState<boolean>(false);
  const [isChecking, setChecking] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const openNotification = (filename: string) => {
    api.success({
      message: "Success",
      description: `You have uploaded your file ${filename} successfully.`,
      placement: "topRight",
    });
  };

  const handleLoadTxt = (event: UploadChangeParam<UploadFile<any>>) => {
    const fileList = event.fileList;
    const file = fileList[fileList.length - 1];
    const reader = new FileReader();

    reader.onload = function (e) {
      const fileContent = e.target?.result;
      dispatch(
        setScenarioFile({
          name: file.name,
          content: fileContent as string,
        })
      );
      openNotification(file.name);
    };

    reader.readAsText(file.originFileObj as Blob);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const content = event.target.value;
    dispatch(
      setScenarioFile({
        name: textFile.name || "unnamed_scenario",
        content,
      })
    );
  };

  const handleCheck = () => {
    if (!textFile.content) {
      api.warning({
        message: "No file",
        description: `Please import text file before checking`,
        placement: "topRight",
      });
      return;
    }

    setChecking(true);

    convertTxtToJSON(textFile).then((res) => {
      if (!res.scenes) {
        api.error({
          message: "Error",
          description: `Something went wrong while converting text file. Please check your file. ${
            res.detail ? `More details about error: ${res.detail}` : ""
          }`,
          placement: "topRight",
        });
        setChecking(false);
        return;
      }
      dispatch(setScenarioJSON(res));
      setChecking(false);
    });
  };

  const handleExportTextFile = () => {
    exportTextFile(`${textFile.name}.txt`, textFile.content);
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="ScenarioEditor">
        <img className="ideo-logo" src={IdeoLogo} alt="ideo log" />
        <Modal
          title="Rules"
          open={isModalOpen}
          onOk={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
          footer={[
            <Button key="submit" type="primary" onClick={() => setModalOpen(false)}>
              Close
            </Button>,
          ]}
        >
          <Rules />
        </Modal>
        <div className="title">
          <Title level={2}>Scenario Editor {textFile.name ? `- ${textFile.name}` : ""}</Title>
          <div className="rules" onClick={() => setModalOpen(true)}>
            {"screenwriting rules"}
            <InfoCircleOutlined />
          </div>
        </div>
        <div className="compare-windows">
          <div className="raw-txt">
            <textarea onChange={handleTextChange} value={textFile.content} />
          </div>
          <div className="visual">{isJSON ? <JsonView value={jsonFile} /> : <ScenarioPreview />}</div>
        </div>
        <div className="menu">
          <div>
            <Upload showUploadList={false} onChange={handleLoadTxt} beforeUpload={() => false}>
              <Button icon={<UploadOutlined />}>Import form txt</Button>
            </Upload>
            <Button icon={<DownloadOutlined />} onClick={handleExportTextFile}>
              Export txt
            </Button>
          </div>
          <div>
            <Button icon={<ReloadOutlined />} onClick={handleCheck} type="primary" loading={isChecking}>
              Check
            </Button>
            {isJSON ? (
              <Button icon={<EyeOutlined />} onClick={() => setIsJSON(false)}>
                Show Preview
              </Button>
            ) : (
              <Button icon={<ApartmentOutlined />} onClick={() => setIsJSON(true)}>
                Show JSON
              </Button>
            )}
          </div>
        </div>
      </div>
    </Context.Provider>
  );
};
