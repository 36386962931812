import { Tabs, Typography } from "antd";
import "./Rules.sass";
import TabPane from "antd/es/tabs/TabPane";

const { Text } = Typography;

export const Rules = () => {
  return (
    <div className="Rules">
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="Quick Start" key="1">
          <div>
            <p>Left side window shows the original file. Right side shows script formatted suggestion.</p>
            <p>Keep in mind that your work is not saved so export txt file before refreshing</p>
          </div>
        </TabPane>

        <TabPane tab="Script Writing Rules" key="2">
          <div>
            <p>
              <strong>1. The script is composed out of Scenes</strong>
            </p>
            <p>Each scene Must have a title of form description(0 tabs) and written in all caps.</p>
            <p>
              <strong>2. Each scene can have elements of two types:</strong>
            </p>
            <ul>
              <li>
                <div>Description (0 tabs), e. g.:</div>
                <div>
                  <Text code>
                    Ant Design (code) The smell of olive oil permeates the air. CHEF JESUS CHAVEZ, a forties Mexican
                    man, flips a tortilla on a grill that also has several octopuses on it.
                  </Text>
                </div>
              </li>
              <br />
              <li>
                <div>Dialog elements, e. g.:</div>
                <div>
                  <Text code>
                    Ant Design (code) RICK BAYLESS surprised These look a little different than what I'm used to working
                    with. These must be from local waters.
                  </Text>
                </div>
              </li>
            </ul>
            <p>
              <strong>3. Do not use any additional formatting</strong>
            </p>
            <p>The tab key for indentations (tabs) is enough.</p>
            <p>
              <strong>4. Always check on the right pane of the screen</strong>
            </p>
            <p>
              If an element does not appear that means that it was not adhering to the rules stated in points 1 or 2.
            </p>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};
