import { createSlice } from "@reduxjs/toolkit";
import { TScenario } from "./types";

export type TTxtFile = {
  name: string;
  content: string;
};

export type TScenarioEditorState = {
  txtFile: TTxtFile;
  jsonOutput: object;
  jsonFile: TScenario | undefined;
};

export const initialState: TScenarioEditorState = {
  txtFile: {
    name: "",
    content: "",
  },
  jsonFile: undefined,
  jsonOutput: {},
};

export const scenarioEditorSlice = createSlice({
  name: "scenarioEditor",
  initialState,
  reducers: {
    setScenarioFile: (state, action) => {
      state.txtFile = action.payload;
    },
    setScenarioJSON: (state, action) => {
      state.jsonFile = action.payload;
    },
  },
});

export const { setScenarioFile, setScenarioJSON } = scenarioEditorSlice.actions;

export default scenarioEditorSlice.reducer;
