import { TTxtFile } from "../components/scenarioEditor/slice";
import { getToken } from "../utils/token";
import { MOVIE_TOOLS_API_URL } from "./constants";

export const convertTxtToJSON = (file: TTxtFile) => {
  const token = getToken();

  return fetch(`https://${MOVIE_TOOLS_API_URL}/scenario-editor`, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(file),
  }).then((res) => res.json());
};
