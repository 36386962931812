import { MOVIE_TOOLS_API_URL } from "./constants";

export const login = (token: string) => {
  return fetch(`https://${MOVIE_TOOLS_API_URL}`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  }).then((res) => res.json());
};
