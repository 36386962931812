import { FC } from "react";
import classnames from "classnames";
import "./ScenarioPreview.sass";
import { useSelector } from "react-redux";
import { TRootState } from "../app/store";
import { TScenario } from "./types";
import "./ScenarioPreview.sass";

type TProps = {};

export const ScenarioPreview: FC<TProps> = () => {
  const scenario = useSelector<TRootState, TScenario | undefined>((state) => state.scenarioEditor.jsonFile);

  if (!scenario) return null;

  return (
    <div className="ScenarioPreview">
      <div className="scenario">
        <div className="about">
          <h2>{scenario.title}</h2>
          <h4>{scenario.description}</h4>
        </div>
        <div className="scenes">
          {scenario.scenes.map(({ title, description, elements }, index: number) => (
            <div className="scene" key={`title-${index}-${title}-${description}}`}>
              <p className="scene-title">{title}</p>
              {description && <p className="scene-description">{description}</p>}
              {elements.length > 0 && (
                <div className="elements">
                  {elements.map(({ text, description, type, start, end, character }, index: number) => (
                    <div
                      className={classnames("element", {
                        description: type === "description",
                        character: character,
                      })}
                      key={`el-${index}-${text}-${description}`}
                    >
                      {character && <p className="el-character">{character}</p>}
                      <p className="el-text">{text}</p>
                      {description && <p className="el-description">{description}</p>}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
