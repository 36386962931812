import { useEffect } from "react";

export const useTabInTextArea = () => {
  const addTab = (e: KeyboardEvent) => {
    if (e.code === "Tab") {
      e.preventDefault();
      document.execCommand("insertText", false, "\t");
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", addTab);

    return () => {
      document.removeEventListener("keydown", addTab);
    };
  }, []);
};
