import { FC, useState } from "react";
import { Alert, Button, Form, Input } from "antd";
import "./login.sass";
import { Links } from "../links/Links";
import { hasTokenSet } from "../../utils/token";
import { login } from "../../api/login";

type FieldType = {
  username?: string;
  password?: string;
};

type TProps = {};

export const Login: FC<TProps> = () => {
  const [isAuth, setAuth] = useState<boolean>(hasTokenSet());
  const [error, setError] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);

  const onFinish = ({ username, password }: FieldType) => {
    setLoading(true);
    const token = btoa(`${username}:${password}`);

    login(token)
      .then((res) => {
        setAuth(true);
        sessionStorage.setItem("auth", token);
      })
      .catch((e): any => {
        setLoading(false);
        setAuth(false);
        setError("Can not login!");
      });
  };

  const onFinishFailed = () => {
    console.log("on finish failed");
  };

  if (isAuth) {
    return <Links />;
  }

  return (
    <div className="Login">
      {error && <Alert message="Error" description={error} type="error" showIcon />}
      <Form
        className="login-form"
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item<FieldType>
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
