import { AuthorizedOnly } from "../components/auth/AuthorizedOnly";
import { Layout } from "../components/layout/Layout";
import { ScenarioEditor } from "../components/scenarioEditor/ScenarioEditor";

export const ScenarioEditorPage = () => {
  return (
    <AuthorizedOnly>
      <Layout>
        <ScenarioEditor />
      </Layout>
    </AuthorizedOnly>
  );
};
