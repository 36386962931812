import { Route, Routes } from "react-router-dom";
import { Login } from "../login/Login";
import { ApplicationRoutes } from "./routes";
import { LinksPage } from "../../pages/LinksPage";
import { ScenarioEditorPage } from "../../pages/ScenarioEditorPage";
import { NotFoundPage } from "../../pages/NotFoundPage";

export const AppRoutes = () => (
  <Routes>
    <Route path={ApplicationRoutes.main} element={<Login />} />
    <Route path={ApplicationRoutes.links} element={<LinksPage />} />
    <Route path={ApplicationRoutes.scenarioEditor} element={<ScenarioEditorPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);
